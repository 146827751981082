.hd-home {
    padding-inline: var(--hd-space-4);
}

.hd-home__heading {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    max-width: 43rem;
}

.hd-home__header {
    font-size: 2.25rem;
    line-height: 1.3333;
}

.hd-home__tagline {
    color: var(--hd-color-neutral-text-weak);
    font-size: 1rem;
    line-height: 1.5;
    margin-top: var(--hd-space-1);
    max-width: 60ch;
}

.hd-home__ctas {
    display: flex;
    gap: var(--hd-space-3);
    justify-content: center;
    margin-top: var(--hd-space-4);
}

.hd-home__features {
    display: grid;
    gap: var(--hd-space-4);
    grid-template-columns: repeat(1, 1fr);
    margin-top: var(--hd-space-10);
    text-align: center;
}

.hd-home__feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hd-home__feature-title {
    justify-content: center;
    display: flex;
    font-size: 1.125rem;
    gap: var(--hd-space-1);
    line-height: 1.3333;
}

.hd-home-feature-title__icon path {
    height: var(--hd-space-3);
    aspect-ratio: 1/1;
    stroke: var(--hd-color-neutral-icon);
}

@media screen and (width >= 37.5rem) {
    .hd-home__features {
        grid-template-columns: repeat(2, 1fr);
        text-align: left;
    }

    .hd-home__feature-item {
        align-items: flex-start;
    }
}

@media screen and (width >= 48rem) {
    .hd-home__features {
        grid-template-columns: repeat(4, 1fr);
    }
}

.hd-icon-fill {
    fill: var(--hd-color-neutral-icon);
    stroke: transparent;
}

.hd-icon-fill path {
    stroke: transparent;
}

.hd-home__feature-copy {
    color: var(--hd-color-neutral-text-weak);
    font-size: 0.875rem;
    line-height: 1.4285;
    margin-top: var(--hd-space-1);
    max-width: 50ch;
}

/* Samples Section */
.hd-home-samples {
    display: grid;
    gap: var(--hd-space-3);
    grid-template-columns: 1fr;
    margin-top: var(--hd-space-10);
}

@media screen and (width >= 78.75rem) {
    .hd-home-samples {
        grid-template-columns: repeat(2, 1fr);
    }
}

.hd-home-sample__item {
    --background: var(--hd-white);
    --color: var(--hd-neutral-50);

    background: linear-gradient(90deg, var(--background) 0.625rem, transparent 1%) 50%, linear-gradient(var(--background) 0.625rem, transparent 1%) 50%, var(--color);
    background-size: 0.75rem 0.75rem;
    border: var(--hd-border-size) solid var(--hd-color-neutral-border);
    border-radius: var(--hd-border-radius-lg);
    padding: var(--hd-space-3);
    position: relative;
}

[data-theme="dark"] .hd-home-sample__item {
    --background: var(--hd-neutral-900);
    --color: var(--hd-neutral-800);
}

a.hd-home-sample__item:hover {
    box-shadow: 0 0.625rem 0.625rem calc(-1 * 0.0313rem) rgba(0 0 0 / 6%), 0 0.1875rem 0.1875rem calc(-1 * 0.0938rem) rgba(0 0 0 / 6%), 0 0.375rem 0.375rem calc(-1 * 0.0938rem) rgba(0 0 0 / 6%), 0 0.75rem 0.75rem calc(-1 * 0.375rem) rgba(0 0 0 / 6%), 0 1.5rem 1.5rem calc(-1 * 0.75rem) rgba(0 0 0 / 6%);
    transform: translate(-0.125rem, -0.125rem);
    transition: box-shadow 0.3s ease, transform 0.2s ease-in;
}

a.hd-home-sample__item:hover .hd-home-sample__title-icon {
    opacity: 1;
    transform: translateX(0);
}

a.hd-home-sample__item:hover::after {
    bottom: -0.25rem;
    content: "";
    display: block;
    height: 0.25rem;
    left: 0;
    position: absolute;
    width: 100%;
}

.hd-home-sample__title {
    align-items: center;
    align-self: flex-start;
    color: var(--hd-color-neutral-text);
    display: flex;
    font-size: 1.25rem;
    gap: 0.25rem;
    line-height: 1.2;
}

.hd-home-sample__title-wrap {
    align-items: center;
    display: flex;
    gap: 0.5rem;
}

.hd-home-sample__title-icon {
    fill: var(--hd-color-neutral-icon);
    opacity: 0;
    transform: translateX(-1rem);
    transition: opacity 0.2s ease, transform 0.1s ease-in;
}

.hd-home-sample__title-tag {
    align-self: center;
    border-radius: var(--hd-border-radius-sm);
    color: var(--hd-color-accent-text);
    background-color: var(--hd-color-accent-surface);
    font-size: 0.75rem;
    font-weight: 400;
    padding: 0.125rem var(--hd-space-05);
}

.hd-home-sample__tagline {
    color: var(--hd-color-neutral-text-weak);
    font-size: 1rem;
    line-height: 1.5;
    margin-top: var(--hd-space-05);
    margin-bottom: var(--hd-space-3);
}

.hd-home-samples__row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
}

@media screen and (width >= 37.5rem) {
    .hd-home-samples__row {
        grid-template-columns: repeat(2, 1fr);
    }
}

.hd-home-samples__col {
    display: grid;
    gap: 1.5rem;
}

.hd-home-samples__main-wrapper {
    grid-template-columns: 1fr;
}

@media screen and (width >= 62rem) {
    .hd-home-samples__main-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (width >= 78.75rem) {
    .hd-home-samples__main-wrapper {
        grid-template-columns: 1fr;
    }
}

/* colors sample */
.hd-home-sample__colors {
    align-self: start;
    display: grid;
    justify-content: flex-end;
    gap: var(--hd-space-1);
    margin-block: calc(-1 * var(--hd-space-1));
}

.hd-home-sample__colors-row {
    display: flex;
    gap: var(--hd-space-1);
}

.hd-home-sample__color {
    border-radius: var(--hd-border-radius-md);
    display: flex;
    height: 1.875rem;
    width: 1.875rem;
}

.hd-home-sample__item-colors {
    display: flex;
    gap: var(--hd-space-1);
    justify-content: space-between;
}

/* Rock Color */
.hd-home-sample__color-rock-200 {
    background-color: #B3B3B1;
}

.hd-home-sample__color-rock-300 {
    background-color: #959593;
}

.hd-home-sample__color-rock-400 {
    background-color: #777775;
}

.hd-home-sample__color-rock-500 {
    background-color: #6C6C6B;
}

/* Sapphire Color */
.hd-home-sample__color-sapphire-200 {
    background-color: #95B1FF;
}

.hd-home-sample__color-sapphire-300 {
    background-color: #6C8FFD;
}

.hd-home-sample__color-sapphire-400 {
    background-color: #4767FE;
}

.hd-home-sample__color-sapphire-500 {
    background-color: #3B57FF;
}

/* Sizes */
.hd-home-sample__sizes {
    display: flex;
    gap: var(--hd-space-1);
    margin-block: calc(-1 * var(--hd-space-1));
}

.hd-home-sample__item-sizes {
    display: flex;
    justify-content: space-between;
    gap: var(--hd-space-1);
    flex-wrap: wrap;
}

.hd-home-sample__size {
    display: flex;
    flex-direction: column;
    gap: var(--hd-space-05);
}

.hd-home-sample__size-text {
    color: var(--hd-color-accent-text);
    font-size: 0.625rem;
    text-align: right;
}

.hd-home-sample__size-bar {
    background-color: var(--hd-color-accent-surface);
    min-height: 3.5rem;
    height: 100%;
}

.hd-home-sample__size-16 .hd-home-sample__size-bar {
    width: 1rem;
}

.hd-home-sample__size-24 .hd-home-sample__size-bar {
    width: 1.5rem;
}

.hd-home-sample__size-32 .hd-home-sample__size-bar {
    width: 2rem;
}

.hd-home-sample__size-40 .hd-home-sample__size-bar {
    width: 2.5rem;
}

/* Icons */
.hd-home-sample__icons {
    display: flex;
    gap: var(--hd-space-2);
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between
}

.hd-home-sample__item-icons {
    display: flex;
    flex-direction: column;
}

.hd-home-sample__icons-row.hd-home-sample__icons-extra-row {
    display: none;
}

@media screen and (width >= 62rem) {
    .hd-home-sample__icons {
        gap: var(--hd-space-3);
    }

    .hd-home-sample__icons-row.hd-home-sample__icons-extra-row {
        display: flex;
    }
}

@media screen and (width >= 78.75rem) {
    .hd-home-sample__icons {
        gap: var(--hd-space-2);
    }

    .hd-home-sample__icons-row.hd-home-sample__icons-extra-row {
        display: none;
    }
}

.hd-home-sample__icons-row {
    display: flex;
    justify-content: space-between;
}

.hd-home-samples .hd-home-sample__icons-icon {
    color: var(--hop-neutral-icon-weak);
}

.hd-home-samples .hd-home-sample__icons-icon--active {
    color: var(--hop-primary-icon);
}

.hd-home-samples .hd-home-sample__icons-icon--strong {
    color: var(--hop-neutral-icon);
}

/* Text Styles */
.hd-home-sample__item-text-styles {
    display: flex;
    flex-direction: column;
}

.hd-home-sample__text-styles {
    border-bottom: 0;
    border-image: linear-gradient(90deg, rgba(0 0 0 / 0%), rgb(186 188 192)) 1;
    border-top: 0;
    border-style: solid;
    border-right: 0;
    border-top-width: var(--hd-border-size);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--hd-space-3);
    margin-top: var(--hd-space-3);
}

@media screen and (width >= 37.5rem) {
    .hd-home-sample__text-styles {
        grid-template-columns: 1fr;
        gap: var(--hd-space-1);
    }
}

@media screen and (width >= 44rem) {
    .hd-home-sample__text-styles {
        grid-template-columns: repeat(2, 1fr);
        gap: var(--hd-space-3);
    }
}

.hd-home-sample__text {
    font-family: Inter, sans-serif;
    font-size: 5.25rem;
    font-weight: 700;
}

.hd-home-sample__text-lowercase {
    color: transparent;
    -webkit-text-stroke: 0.125rem var(--hd-color-neutral-text);
}

.hd-home-sample__text-styles-col {
    display: flex;
    align-items: center;
}

.hd-home-sample__text-styles-controls {
    --background: var(--hd-white);

    align-items: flex-start;
    background-image: linear-gradient(180deg, var(--background) 0%, var(--background) 75%, rgba(255 255 255 / 0%) 100%);
    border-bottom: 0;
    border-image: linear-gradient(0deg, rgba(0 0 0 / 0%), rgb(186 188 192)) 1;
    border-style: solid;
    border-right: 0;
    border-top: 0;
    border-left-width: var(--hd-border-size);
    display: grid;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.75rem;
    padding: var(--hd-space-2);
}

@media screen and (width >= 37.5rem) {
    .hd-home-sample__text-styles-controls {
        grid-template-columns: repeat(2, 1fr);
        border-image: linear-gradient(90deg, rgba(0 0 0 / 0%), rgb(186 188 192)) 1;
        border-style: solid;
        border-top-width: var(--hd-border-size);
    }
}

@media screen and (width >= 44rem) {
    .hd-home-sample__text-styles-controls {
        grid-template-columns: 1fr;
        border-image: linear-gradient(0deg, rgba(0 0 0 / 0%), rgb(186 188 192)) 1;
        border-top: 0;
    }
}

[data-theme="dark"] .hd-home-sample__text-styles-controls {
    --background: var(--hd-neutral-900);
}

.hd-home-sample__control-knob {
    align-items: center;
    display: flex;
    gap: 0.75rem;
}

.hd-home-sample__control-knob-title {
    align-items: center;
    display: flex;
    gap: 0.25rem;
}

.hd-home-sample__control-knob-value {
    color: var(--hd-color-neutral-text);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    line-height: 1;
}

/* Text Styles - Control Text */
.hd-home-sample__control-title {
    font-family: Inter, sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.3333;
}

/* Components */
.hd-home-sample__item-components {
    display: grid;
    grid-template-rows: min-content min-content 1fr;
}

.hd-home-sample-components {
    display: grid;
    grid-template-columns: 1fr;
}

@media screen and (width >= 44rem) {
    .hd-home-sample-components {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (width >= 62rem) {
    .hd-home-sample-components {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (width >= 78.75rem) {
    .hd-home-sample-components {
        grid-template-columns: repeat(2, 1fr);
    }
}

.hd-home-sample-components__item-wrapper {
    display: flex;
}

.hd-home-sample-components__item {
    --background: rgba(255 255 255 / 70%);

    align-items: center;
    display: flex;;
    flex-grow: 1;
    justify-content: center;
    padding: 1rem;
}

@media screen and (width >= 78.75rem) {
    .hd-home-sample-components__item-wrapper:nth-child(odd) {
        background: linear-gradient(-90deg, var(--background) 0%, var(--background) 85%, rgba(0 212 255 / 0%) 100%);
        border-bottom: 0;
        border-image: linear-gradient(90deg, rgba(0 0 0 / 0%), rgb(186 188 192)) 1;
        border-style: solid;
        border-right: 0;
        border-left: 0;
        border-top-width: var(--hd-border-size);
    }

    .hd-home-sample-components__item-wrapper:nth-child(even) {
        background: linear-gradient(90deg, var(--background) 0%, var(--background) 85%, rgba(255 255 255 / 0%) 100%);
        border-bottom: 0;
        border-image: linear-gradient(-90deg, rgba(0 0 0 / 0%), rgb(186 188 192)) 1;
        border-style: solid;
        border-right: 0;
        border-left: 0;
        border-top-width: var(--hd-border-size);
    }

    .hd-home-sample-components__item-wrapper:nth-child(2) .hd-home-sample-components__item {
        border-bottom: 0;
        border-image: linear-gradient(-90deg, rgba(0 0 0 / 0%), rgb(186 188 192)) 1;
        border-style: solid;
        border-right: 0;
        border-left-width: var(--hd-border-size);
        border-top: 0;
    }

    .hd-home-sample-components__item-wrapper:nth-child(4) .hd-home-sample-components__item {
        border-bottom: 0;
        border-image: linear-gradient(0deg, rgba(0 0 0 / 0%) 0%, rgba(0 0 0 / 0%) 25%, rgb(186 188 192)) 1;
        border-style: solid;
        border-right: 0;
        border-left-width: var(--hd-border-size);
        border-top: 0;
    }
}

[data-theme="dark"] .hd-home-sample-components__item {
    --background: rgba(41 41 41 / 70%);
}

/* Components - Fake */
.hd-home-sample-components__buttons {
    display: flex;
    gap: var(--hd-space-2);
}

/* Upcoming */
.hd-home-sample__item-upcoming {
    background-image: linear-gradient(0deg, rgba(202 79 79 /  20%) 0%, rgba(60 60 60 / 50%) 75%);
    border-radius: inherit;
    display: flex;
    inset: 0;
    opacity: 0.1;
    position: absolute;
    z-index: 10;
}

[data-theme="dark"] .hd-home-sample__item-upcoming {
    background: linear-gradient(0deg, rgba(80 80 80 / 20%) 0%, rgba(80 80 80 / 60%) 75%);
    opacity: 0.6;
}
